import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import first from "../../Assets/images/masonary/1.jpg";  
import second from "../../Assets/images/masonary/2.jpg";  
import third from "../../Assets/images/masonary/3.jpg";  
import fourth from "../../Assets/images/masonary/4.jpg";  
import fifth from "../../Assets/images/masonary/5.jpg";  
import sixth from "../../Assets/images/masonary/6.jpg";  
import seven from "../../Assets/images/masonary/7.jpg";  
import eight from "../../Assets/images/masonary/8.jpg";  
 

const Momentoflove = () => {

  const images: CustomImage[] = [ 
    {
      src: first,
      original: first, 
    },
    {
      src: second,
      original: second,   
    },
    
    {
      src: third,
      original: third,  
    },
    
    {
      src: fourth,
      original: fourth, 
             
    },
    
    {
      src: fifth,
      original: fifth,   
        
    },
    {
      src: sixth,
      original: sixth,      
    },
    {
      src: seven,
      original: seven,      
    },
    {
      src: eight,
      original: eight,      
    },
   
  ];

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: Number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  
    return (
        <>
          <div className='gallery'>
          <Container className="rcs_custom_home_container "> 
                    <Row className="">                        
                        <Col md={12}>
                            <div className="rcs_shop_by_diamond_title" data-aos="fade-up">
                                <h2 className='rcs_subm-head'>
                                Pick Your Ring & Share your Dream Proposal with us!
                                </h2>
                                <p>Here’s the Moments of Love!</p>   
                            </div>  
                        </Col> 
                    </Row>
                    <Row className="" >                        
                        <Col md={12}>
                        <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
                        </Col>
                        </Row>
                    </Container>
          </div>

        </>
    );
}

export default Momentoflove;
