import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import engring from "../../Assets/images/home/category/engagement-ring.jpg";
import wedring from "../../Assets/images/home/category/wedding-ring.jpg";
import pendants from "../../Assets/images/home/category/pendants.jpg";
import earring from "../../Assets/images/home/category/earrings.jpg";
import necklace from "../../Assets/images/home/category/necklace.jpg";
import bracelet from "../../Assets/images/home/category/bracelet.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component"; 

const ExploreCollection = () => {
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className="rcs_collection_section1 ">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_collection1_title rcs__mobile_collection_title">
                                <h2> Shop By Category </h2>
                                <p>Find Exactly What You Need</p>  
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="rcs_customer_review_slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>
                                <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                            <Link to="/ringsettings">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={engring} alt="shop-engagement-ring" title="shop-engagement-ring" />
                                        </div> 
                                        <div className="rcs_collection1_box_btn ">
                                                <h2>Engagement Rings </h2>
                                        </div>
                                            </Link>
                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <Link to="jewelry/wedding-rings">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={wedring} alt="shop-wedding-ring" title="shop-wedding-ring" />
                                        </div>
                                        <div className="rcs_collection1_box_btn ">
                                           
                                                <h2>Wedding Rings </h2>
                                        </div>
                                            </Link>
                                    </div>
                                    {/* <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={fashionring} alt="shop-ring" title="shop-ring" />
                                        </div>
                                        <div className="rcs_collection1_box_btn "> 
                                            <Link to="/jewelry/fashion-rings">
                                                <h2>Fashion Rings </h2>
                                            </Link>
                                        </div>
                                    </div> */}
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <Link to="/jewelry/pendants">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={pendants} alt="shop-pendants" title="shop-pendants" />
                                        </div>
                                        <div className="rcs_collection1_box_btn"> 
                                                <h2> Pendants </h2>
                                        </div>
                                            </Link>
                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <Link to="/jewelry/necklaces">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={necklace} alt="shop-necklace" title="shop-necklace" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            
                                                <h2> Necklaces </h2>
                                        </div>
                                            </Link>
                                    </div>
                                   
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <Link to="/jewelry/bracelets">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={bracelet} alt="shop-bracelet" title="shop-bracelet" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                           
                                                <h2> Bracelets </h2>
                                        </div>
                                            </Link>

                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box" >
                                    <Link to="/jewelry/earrings">
                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={earring} alt="shop-earring" title="shop-earring" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                                <h2> Earrings </h2>
                                        </div>
                                            </Link>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    );
}
export default ExploreCollection;
