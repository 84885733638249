import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Ringguide from "../../Assets/images/home/ring-guide.jpg"; 
import Diamondguide from "../../Assets/images/home/diamond-guide.jpg"; 
import Customjewelry from "../../Assets/images/home/custom-jewelry.jpg"; 

const BecomeanExpert = () => {
    const history = useHistory(); 
    return (
        <>
             <section className="becomeanexpert">
                <Container className="rcs_custom_home_container ">
                    <Row className="">                        
                        <Col md={12}>
                            <div className="rcs_shop_by_diamond_title" data-aos="fade-up">
                                <h2 className='rcs_subm-head'>
                                Become an Expert
                                </h2>
                                <p>Level Up Your Knowledge</p>  
                            </div>  
                        </Col>
                    </Row>
                    <Row className="">                        
                        <Col md={4}>
                            <div className='expert-box'>
                            <LazyLoadImage src={Ringguide} alt="Ring Guide" title="Ring Guide"/>
                            <div className='expert-desc'>
                                <h3>Ring Guides</h3>
                               <p>How to buy an engagement ring. Easy guidance on 
how to set a budget, choose a ring metal, and find
the right style and ring size when buying a ring.</p> 
                            <Button variant="outline-dark" onClick={() => history.push('/education/engagement-ring-education')} className='rcs_hbtn'>Learn More</Button>
                            </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='expert-box'>
                            <LazyLoadImage src={Diamondguide} alt="Diamond Guides" title="Diamond Guides"/> 
                            <div className='expert-desc'>
                                <h3>Diamond Guides</h3>
                               <p>We have created a comprehensive collection of
diamond guides, covering everything from diamond
shapes to lab grown diamonds.</p> 
                            <Button variant="outline-dark" onClick={() => history.push('/education/loose-diamonds-education')} className='rcs_hbtn'>Learn More</Button>
                            </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='expert-box'>
                            <LazyLoadImage src={Customjewelry} alt="Ring Guide" title="Ring Guide"/> 
                            <div className='expert-desc'>
                                <h3>Custom Jewellery</h3>
                               <p>From concept to creation, custom jewellery is a
testament to your distinctiveness and can become a
cherished heirloom for generations to come.</p> 
                            <Button variant="outline-dark" onClick={() => history.push('/custom-design')} className='rcs_hbtn'>Learn More</Button>
                            </div>
                            </div>  
                        </Col>
                        </Row> 
                </Container>  
            </section>

        </>
    );
}

export default BecomeanExpert;
