import { RiFacebookBoxFill, RiTwitterFill, RiInstagramLine, RiLinkedinBoxFill, RiFacebookFill } from "react-icons/ri";
import { AiOutlineGooglePlus } from "react-icons/ai";  
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/1.png';
import Social2 from '../Assets/images/static/Social/2.png';
import Social3 from '../Assets/images/static/Social/3.png';
import Social4 from '../Assets/images/static/Social/4.png';
import Social5 from '../Assets/images/static/Social/5.png';
import Social6 from '../Assets/images/static/Social/6.png';
import Social7 from '../Assets/images/static/Social/7.png';
//-------------------------------------------------url

export const base_url = "https://heartofdiamond.co.uk/webapi/api";
export const img_url = "https://heartofdiamond.co.uk/"; 
export const helmet_url = "https://heartofdiamond.co.uk/";

// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text: '0203 302 1101',
  link: `tel:0203 302 1101`
};
export const scnd_number = {                // <===---contact Number // 
  text: '+44 0 203 302 1101',
  link: `tel:+44 0 203 302 1101`  
}; 
export const Email_details = {                      // <===---Email_Details  //
  text: 'sales@heartofdiamond.co.uk',
  link: `mailto:sales@heartofdiamond.co.uk`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text: 'sales@heartofdiamond.co.uk', 
  link: `mailto:sales@heartofdiamond.co.uk` 
};

// export const Address_details = {                     // <===---Address_details  // 
//   textLine1: 'Heart of Diamond, 18 Hatton Garden,',
//   textLine2: 'London, EC1N 8AT',
//   link: "https://www.google.com/maps/dir//20+W+47th+St+%23601,+New+York,+NY+10036,+USA/@40.7570155,-74.0148537,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c258fe544d7d25:0xeb7d6ed406a3793e!2m2!1d-73.9798343!2d40.7569558"
// };
export const Address_details = {                     // <===---Address_details  // 
  textLine1: 'Heart of diamond, No.23,',
  textLine2: ' Hatton Garden, London EC1N 8BQ',
  link: "https://www.google.com/maps/place/Heart+of+Diamond/@51.5192203,-0.1103491,17z/data=!3m1!4b1!4m6!3m5!1s0x48761b4db86f1311:0x51932ef0ef7bb162!8m2!3d51.5192203!4d-0.1077742!16s%2Fg%2F11h2lq48pn?entry=ttu"
};
export const client_name = {                          // <===---client_name  // 
  c_name: 'Heart of Diamonds', 
};

export const media_details = [                           // <===---media_details  // 
  {
    account_alt: 'Facbook',
    account_icon: <RiFacebookFill />,
    account_img: Social1,
    account_link: "https://www.facebook.com/heartofdiamond.co.uk"
  },
  {
    account_alt: 'Twitter',
    account_icon: <RiTwitterFill />,
    account_img: Social7,
    account_link: "https://twitter.com/Heart_ofDiamond"
  },
  {
    account_alt: 'Instgram',
    account_icon: <RiInstagramLine />,
    account_img: Social2,
    account_link: "https://www.instagram.com/heartofdiamond.co.uk/"
  },
  // {
  //   account_alt: 'Linkedin',
  //   account_icon: <RiLinkedinBoxFill />,
  //   account_img: Social3,
  //   account_link: "https://www.linkedin.com/company/belgium-webnet/" 
  // },
  {
    account_alt: 'Google',
    account_icon: <AiOutlineGooglePlus />,
    account_img: Social3,
    account_link: "https://plus.google.com/103321913239098640918"
  },
  
];


export const InvoiceAddress = () => {              // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">Heart of Diamond, 18 Hatton Garden,</h4>
      <h4 className="mb-0"> London, EC1N 8AT</h4>
    </>
  )
};

// ------Contact Details End ------

export const user = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")) : [];

export const isLogin = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")).isLogin : false;
export const currencycode = localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : 'USD';
export const currency = localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : '$';
//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
  return isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }
}

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-stud-diamonddata')) ? JSON.parse(localStorage.getItem('bw-stud-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-stud-settingdata')) ? JSON.parse(localStorage.getItem('bw-stud-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {  
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  }
}


//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {


  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }

  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail-image")[1].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[1].src = img;
      }
    }
    var imgsrc = document.getElementsByClassName("image-gallery-thumbnail active")[0].getElementsByTagName("img")[0].getAttribute("src");
    var image = new Image();
    image.src = imgsrc;
    image.onerror = () => {
      document.getElementsByClassName("image-gallery-thumbnail-image")[type == "Complete" ? 1 : 0].src = img;
      document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = img;
    }
    image.onload = () => {
      document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    }
  } else {
    console.log("ImageError sku: ", id)
    document.getElementById(id).src = img;
  }
}