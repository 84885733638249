import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
        <div className='instafeed'>
            <Container>
                <Row>
                <Col lg="12" col="12">
                            <div className="rcs_customer_say_title" data-aos="fade-up" data-aos-duration="1000">
                                <h2> View our Instagram </h2>
                                <p>Follow us @heartofdiamond.co.uk</p>
                            </div>
                        </Col>
                </Row>
            </Container> 
            <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> 
            </div>
        </>
    )
}

export default Insta;