import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Solitaire from '../../Assets/images/engagement/solitaire.png';
import Pave from '../../Assets/images/engagement/pave.png';
import Halo from '../../Assets/images/engagement/halo.png';
import Threestone from '../../Assets/images/engagement/three-stone.png';
import Bypass from '../../Assets/images/engagement/bypass.png';
import Multirow from '../../Assets/images/engagement/multirow.png';
import ChannelSet from '../../Assets/images/engagement/channel.png';
import SideStone from '../../Assets/images/engagement/sidestone.png'; 
import vintage from '../../Assets/images/engagement/vintage.png'; 
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
const style_submenu = [
    {
        "menu_id": "24",
        "name": "Halo",
        "img_url": Halo,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Halo",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "18",
        "name": "Solitaire",
        "img_url": Solitaire,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Solitaire",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "17",
        "name": "Three Stone",
        "img_url": Threestone,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Three%20stone",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    // {
    //     "menu_id": "20",
    //     "name": "Single Row",
    //     "img_url": "https:\/\/dl2vs6wk2ewna.cloudfront.net\/scrap\/showcase\/header\/style\/eng\/channel-set.png",
    //     "icon": "",
    //     "alt_tag": "",
    //     "slug": "\/ringsettings\/style=Single Row",
    //     "col": "3",
    //     "design": "1",
    //     "submenu": []
    // },
   
    // {
    //     "menu_id": "204",
    //     "name": "Bypass",
    //     "img_url": Bypass, 
    //     "icon": "",
    //     "alt_tag": "",
    //     "slug": "\/ringsettings\/style=Bypass", 
    //     "col": "3",
    //     "design": "1",
    //     "submenu": []
    // },
    {
        "menu_id": "23",
        "name": "Pave",
        "img_url": Pave,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Pave",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "203",
        "name": "Multirow",
        "img_url": Multirow,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Multirow",
        "col": "3",
        "design": "1",
        "submenu": []
    },
    {
        "menu_id": "21",
        "name": "Vintage",
        "img_url": vintage,
        "icon": "",
        "alt_tag": "",
        "slug": "\/ringsettings\/style=Vintage",
        "col": "3",
        "design": "1",
        "submenu": []
    }
]

const RingStyle = () => {
    var history = useHistory();

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000, 
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settingsV2 = {
        dots: false,
        arrows: true,
        centerMode: true,
        // infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,  
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            }
        ]
    };

    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
        history.push(value);
    }
    return (
        <>
            <Container fluid className='mt-3' >
                <div className='rcs_ringstyle rcs_ringstyle_v2' >
                    <Row>
                        <Col lg={12} xs={12}>
                            <div className='rcs_ringstyle_title'>
                                <h2 className='rcs_subm-head'> Shop By Style</h2> 
                                <p>Find Your Signature Look</p> 
                            </div>   
                        </Col>
                    </Row>

                    <Slider {...settingsV2}>
                        {style_submenu?.map((p_style, i) =>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={p_style?.img_url} alt='Solitaire' onClick={() => shopbystyle(p_style?.slug)} />
                            </div>
                            <h2 onClick={() => shopbystyle(p_style?.slug)}>{p_style?.name}</h2>
                        </div>
                        )}
                        {/* <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={Solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')} />
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={Pave} alt='Pave' />
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={Halo} alt='Halo' />
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={ThreeStone} alt='Three Stone' />
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Channel Set')} src={ChannelSet} alt='Channel Set' />

                            </div>
                            <h2 onClick={() => shopbystyle('Channel Set')}>Channel Set</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Multirow')} src={Multirow} alt='Multirow' />
                            </div>
                            <h2 onClick={() => shopbystyle('Multirow')}>Multirow</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Bypass')} src={Bypass} alt='Bypass' />
                            </div>
                            <h2 onClick={() => shopbystyle('Bypass')}>Bypass</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Side stone')} src={SideStone} alt='Side-stone' />
                            </div>
                            <h2 onClick={() => shopbystyle('Side stone')}>Side Stone</h2>
                        </div> */}

                    </Slider>

                </div>


            </Container>
        </>
    );
}

export default RingStyle;