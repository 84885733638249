import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';

const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);

  useEffect(()=>{
 //-----------------------------------Banner
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])
  return (
    <>
      {banner?.map((val, index) =>
      <div className='rcstopmain'>
      <Container className='rcs_custom_home_container container'>
        <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
              <Row className='m-auto w-100'>
                <Col md={10} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
          </div>
        </div>
            </Container>
            </div>
      )}
    </>
  )
}
 
export default BannerSlider;