import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import signupTo from "../../Assets/images/home/signup-to.webp";
import { base_url, Address_details, client_name, Email_details, media_details, Email_detailsSecond,  contact_number, currency, currencycode, helmet_url, postHeader } from '../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { LazyLoadImage } from "react-lazy-load-image-component"; 
import contact from '../../Assets/images/icon/call.svg';  
import address from '../../Assets/images/icon/location.svg';  
import mail from '../../Assets/images/icon/mail.svg';  
 
function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <>
            <section className="rcs_signup_to_section mt-5">
                <Container className='rcs_custom_home_container'>
                    <Row>
                    <Col lg={6} xs={12} data-aos="fade-up" data-aos-duration="1200"  data-aos-easing="ease-in-sine">
                        <div className='git-box'> 
                  <h2 className='mb-3'>Get in Touch</h2>
                  <ul className='get-touch'>
                    <li onClick={() => { window.open(Address_details.link, '_blank') }}>
                    <LazyLoadImage src={address} alt='address'/>
                      {Address_details.textLine1}
                      <br />
                      {Address_details.textLine2}
                    </li>
                    <li onClick={(e) => { window.location.href = contact_number.link }}> <LazyLoadImage src={contact} alt='Contact'/> {contact_number.text}</li>
                    <li onClick={(e) => { window.location.href = Email_details.link }}> <LazyLoadImage src={mail} alt='email address'/>{Email_details.text}</li>
                  </ul>
                </div>
                        </Col> 
                        <Col lg={6} xs={12}  data-aos="fade-up" data-aos-duration="1200"  data-aos-easing="ease-in-sine">
                            <div className="rcs_signup_to_left_main">
                                <div className="rcs_signup_to_title">
                                    <h2>Subscribe To Our Newsletter</h2>
                                    <p >Keep up to date with our latest offers, competitions
and news, and be a part of the {client_name.c_name} community!</p>
                                </div>
                                <div className="rcs_signup_to_input_field">
                                    <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                            <FormControl
                                                placeholder="Your Email Address..."
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                type="email"
                                                value={newsletteremail}
                                                required
                                                onChange={(e) => setNewsletteremail(e.target.value)}
                                            />
                                            <Button variant="outline-secondary" className='rcs_hbtn' type="submit" id="button-addon2">
                                                Subscribe
                                            </Button>
                                    </Form>
                                    {/* <p>By signing up you confirm that you have read the <a href="/privacy-policy">Privacy Policy</a> and agree that your email
                                        will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p> */}
                                </div>
                            </div>
                        </Col>
                       
                    </Row>
                </Container>
            </section>
            <Modal show={loader}> 
            <Loader/>
        </Modal>
        </>
    );
}


export default SignupTo;
