import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import AboutImg from "../../Assets/images/about.jpg"; 
import calendarIcn from "../../Assets/images/home/calender-icn.webp";
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Virtualappointment = () => {
    const history = useHistory();
    return (
        <>
             <section className="rcs_color_virtual_section1">
                <Container className="rcs_custom_home_container pr-0">
                    <Row className="rcs_virtual_appointment_row">                        
                        <Col lg="6"  col="12"  className="rcs_order_cs">
                            <div className="rcs_virtual_appointment_text" data-aos="fade-up">
                               <h2> About Heart Of Diamond </h2>
                               <p>
                               We are a globally trusted purveyor of personalised, affordable
and high-quality diamonds and jewellery. Our collection is
sold in London.
                               </p>
                               <Button variant="outline-dark" onClick={() => history.push('/our-story')} className='rcs_hbtn'>Know More</Button>
                                {/* <Button className="rcs_fill_button rcs_btn_rd-0 rcs_new_bg" onClick={() => {history.push('make-an-appointment');window.scrollTo(0,0)}}> 
                                  <LazyLoadImage src={calendarIcn} /> Schedule an Appointment 
                                </Button> */}
                            </div>
                        </Col>
                        <Col lg={{span:5, offset: 1}} col="12" className="p-0">
                            <div className="rcs_virtual_appointment_img">
                              <LazyLoadImage src={AboutImg} alt="About Us" title="About Us"/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

        </>
    );
}

export default Virtualappointment;
