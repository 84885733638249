import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import Gimg from "../../Assets/images/home/google-img.webp";
import userImg from "../../Assets/images/home/slide-user-img.png";
import googleReview from "../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Customer = () => {
    const [customerData,setCustomerData] = React.useState([])
    React.useEffect(()=>{
        GetCustomerRewies();
        console.log('customerdta',customerData)
     },[])
     const GetCustomerRewies = () =>{
         axios.get(`${base_url}/common/site_reviews`,{
            headers: postHeader
        }).then(res=>{
            console.log(res)
            if(res.data.status == 1){
                setCustomerData(res.data)
            }
        }).catch(error=>{
            console.log(error)
        })
     }

    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    

    return (
        <>
            <section className="rcs_customer_say_section">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_customer_say_title" data-aos="fade-up" data-aos-duration="1000">
                                <h2> Our Happy Customers </h2>
                                <p>Here’s the Words of Praise of our Customers</p>
                            </div>
                        </Col>
                        {/* <Col lg={6} xs={12} sm={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_google_rating"  onClick={() => window.open(customerData?.google_review_url, "_blank")}>
                                <div className="rcs_customer_google_rating_img">
                                    <LazyLoadImage src={Gimg} alt="Google Image" />
                                </div>
                                <div className="rcs_customer_google_rating_star"> 
                                    <h4>{(customerData?.average_rating)?.toFixed(1)}</h4>
                                    <Stack spacing={1}>
                                        <Rating name="half-rating-read" defaultValue={5} precision={customerData?.average_rating} size="large" readOnly />
                                    </Stack>
                                </div>
                            </div>
                        </Col> */}
                        
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
                            <div className="rcs_customer_review_slider main-review-slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            Went looking for an upgrade and they had various sized diamonds to try on. This was the 5ct one we went for and it is lovely. Salji was the person who looked after us and the prices quoted were very competitive. Timings for item being ready were as originally quoted. Have already recommended this place to others.
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>Thuy M</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            We had a great experience working with Salji and the team at Heart of Diamond. We wanted a custom ring that could be worn for generations and they helped us get there. We started looking for an engagement ring and then decided to get all of our rings from them. The prices are reasonable and the team was very patient and helped us get the right look we wanted.
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>Joy Adams</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            I bought an engagement ring for my fiance and I was delighted with the quality, service and variaty of ring/stone styles presented in Heart of Diamond. Very friendly staff helped me to pick the right band and stone and they were open to make slight alterations to the bend to match with my image of a perfect engagement ring.
Recommend to everyone!
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>Адель Аль-Фарадж</h3>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                                
                                            I had a great experience with Heart of diamond.

I purchased 3 rings which look amazing.

Ansaf and Salji were great! Really good customer service and even with a few very picky requests, they were very personable, accommodating and understanding.

Definitely recommend if you are buying any jewellery, especially diamond rings!

Thanks again guys, appreciate everything!

Aman
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>amanpreet sangha</h3>
                                            </div> 
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            Salji and the team at Heart of Diamond were amazing from start to finish. After searching in many other shops (both in Hatton Garden and elsewhere in the UK) for a particular diamond to create a bespoke engagement ring, I came across Salji and found the real gem of Hatton Garden. He was able to source the unusual stone that I was looking for without any hassle, judgement about my taste or hard selling, and took myself and my partner through the entire process of creating a ring, reassuring us throughout and delivering on his promises. His prices are also very reasonable and are not artificially marked up like so many other stores I visited which is a sign of his integrity. I will be returning to Salji for all of my jewellery needs in the future, and will be encouraging others to do the same. Many thanks
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                               
                                                <h3>Ashleigh</h3>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            Salji was amazing to deal with. I bought my wife’s ring and my wedding band was also bought from him with zero problems.

Spent thousands of pounds with him with no hassle. Always keeps contact through the making process and sends regular updates and pictures.

His prices were competitive and service was better than most in Hatton garden.

No forceful business and a very smooth process from start to finish. Highly recommend.

A true gentleman.
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>Mutasim Chowdhury</h3>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            I had almost given up hope that I would find someone to make my dream engagement ring when I found Salji and his team at Heart of Diamond. The service was impeccable, highly consumer orientated, I felt I was listened to really well, and I felt very comfortable with my demands on getting the ring to exactly how I wanted it to be. I chose a stunning high quality solitaire diamond and worked with the team to create the setting I had in mind. Everything was done very quickly and at such a good price, i HIGHLY recommend Heart of Diamond if you have an idea what you want and are having a difficult time finding the right people to have it made! 
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                               
                                                <h3>Gillian Bertram</h3>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_customer_review_slide_item">
                                       <div className='gorating-box'>
                                        <LazyLoadImage src={googleReview} />
                                        </div> 
                                        <div className="rcs_customer_slide_text_content">
                                            <p>
                                            The jeweler is located within a complex called The Wonder Gallery in Hatton Garden. It is the first shop on the left, from the left side entrance. We were attended by Salji, he was very helpful. Guided us throughout the process. Even when I had my doubts after the order was made, he patiently helped me through that. The final product was great, we were very very happy with the purchase. This jeweler also gives great value for money. Would highly recommend!!! 
                                            </p>
                                            <Link to="#" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}>  Read More </Link>
                                           
                                        </div>
                                        <div className='rating-box'>
                                        <Rating name="half-rating-read" defaultValue={5} precision={5} size="medium" readOnly />
                                        </div>
                                        <div className="rcs_customer_slide_title_img">
                                            {/* <div className="rcs_customer_review_slide_img">
                                                <LazyLoadImage src={customdata?.profile} />
                                            </div> */}
                                            <div className="rcs_customer_review_slide_title"> 
                                                <h3>priyanka pandey</h3>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Slider>
                            </div>
                        </Col> 
                        <Col lg={12} xs={12} sm={12} data-aos="fade-up" data-aos-duration="1000">
                            <div className="rcs_customer_review_btn">
                                <Button className="rcs_hbtn" onClick={() => window.open("https://www.google.com/search?q=heart+of+diamonds+london&sca_esv=598719896&rlz=1C1CHBD_en-GBIN1072IN1073&ei=4CimZbTxKu-94-EPz4iX4AQ&ved=0ahUKEwi04fqpquGDAxXv3jgGHU_EBUwQ4dUDCBA&uact=5&oq=heart+of+diamonds+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiGGhlYXJ0IG9mIGRpYW1vbmRzIGxvbmRvbjIGEAAYFhgeMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGA0igL1D3HFiKLHABeAGQAQCYAbsCoAHBDKoBBzAuNy4wLjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIIEAAYFhgeGA_CAgcQABiABBgKwgIHEC4YgAQYCuIDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x48761b4db86f1311:0x51932ef0ef7bb162,1,,,,", "_blank")}> View All Reviews </Button>
                            </div>  
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Customer;
