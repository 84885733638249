import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Ringguide from "../../Assets/images/home/ring-guide.jpg"; 
import Riskfree from "../../Assets/images/home/guarantee/risk-free.svg"; 
import Lifetimew from "../../Assets/images/home/guarantee/lifetime-warranty.svg"; 
import Diaexpert from "../../Assets/images/home/guarantee/dia-expert.svg"; 
import MBG from "../../Assets/images/home/guarantee/mbg.svg"; 
import hqvideo from '../../Assets/images/home/diamond-iframe.gif';  

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Guarantee = () => {
    const history = useHistory();  

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1 
                }
            }
        ]
      };
    return (
        <>
             <section className="guarantee-box">
                <Container className="rcs_custom_home_container ">
                    <Row className="">                        
                        <Col md={12}>
                            <div className="rcs_shop_by_diamond_title" data-aos="fade-up"> 
                                <h2 className='rcs_subm-head'>
                                Heart Of Diamond Guarantee
                                </h2>
                                <p>We’ll be available for you anytime and anywhere</p>  
                            </div>  
                        </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                         <Slider className='guarantee-option' {...settings}>
                             <div className='gntopt'>
                             <LazyLoadImage src={Riskfree} alt="Risk Free 30 Days Returns" title="Risk Free 30 Days Returns"/>
                             <span>Risk Free 30 Days Returns</span>
                             </div>
                             <div className='gntopt'>
                             <LazyLoadImage src={Lifetimew} alt="Lifetime Warranty" title="Lifetime Warranty"/>
                             <span>Lifetime Warranty</span>
                             </div>
                             <div className='gntopt'>
                             <LazyLoadImage src={Diaexpert} alt="24/7 Diamond Experts" title="24/7 Diamond Experts"/>
                             <span>24/7 Diamond Experts</span>
                             </div>
                             <div className='gntopt'>
                             <LazyLoadImage src={MBG} alt="100% Money Back Guarantee" title="100% Money Back Guarantee"/>
                             <span>100% Money Back Guarantee</span>   
                             </div>
                         </Slider>
                    </Col>
                    </Row>
                </Container>  
            </section>
            {/* start ap changes */}
<div className='rcs_create_sec exploredia-hq'> 
<Container className='rcs_custom_home_container'> 
    <Row>
        <Col sm={6}>
            <div className='diamond-360' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
        <LazyLoadImage src={hqvideo} alt='360 Diamond'/>
            </div>
        </Col>
        <Col sm={6}>
            <div className='fr-right'>
                <h2><span>Explore Diamonds with</span>HQ 360 videos</h2>      
                      <p>We get it - you want to know every detail of your diamond. And so
you should. That’s why we’ve created a live diamond feed, complete
with detailed 360 videos. Find your perfect diamond.</p>   
<div className='fr-btn-grp'> 
<Button variant="outline-dark" onClick={() => history.push('/diamonds')} className='rcs_hbtn rcs_btnsred'>Shop Natural Diamond</Button>
<Button variant="outline-dark" onClick={() => history.push('/diamonds/lab')} className='rcs_hbtn rcs_btnswhite'>Shop Lab Grown Diamond</Button>
</div>   
            </div>  
        </Col>
    </Row>
</Container>
</div>
{/* end ap changes */}

        </>
    );
}

export default Guarantee;
