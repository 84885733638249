import { Button } from '@mui/material';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ringSetting from '../../Assets/images/home/ring-setting.webp';
import settingDiamonds from '../../Assets/images/home/setting-diamonds.webp';
import finalRing from '../../Assets/images/home/final-ring.jpg';  
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Ringbuilderhome = () => {
    const history = useHistory();
    return (
        <>
{/* start ap changes */}
<div className='rcs_create_sec'>
<Container className='rcs_custom_home_container'> 
    <Row>
        <Col sm={6}>
            <div className='final-ring-img' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
        <LazyLoadImage src={finalRing} alt='Diamond Ring'/>
            </div>
        </Col>
        <Col sm={6}>
            <div className='fr-right'>
                <h2><span>Create Your Own</span>Diamond Ring</h2>      
                      <p>Surround the precious gemstone with a halo of smaller, 
perfectly cut diamonds, amplifying its radiance and adding 
a touch of timeless elegance.</p>   
<div className='fr-btn-grp'> 
<Button variant="outline-dark" onClick={() => history.push('/ringsettings')} className='rcs_hbtn'>start with a setting</Button>
<Button variant="outline-dark" onClick={() => history.push('/diamonds/lab')} className='rcs_hbtn'>start with a lab diamond</Button>
<Button variant="outline-dark" onClick={() => history.push('/diamonds')} className='rcs_hbtn'>start with a natural diamond</Button>
</div> 
            </div>  
        </Col>
    </Row>
</Container>
</div>
{/* end ap changes */}
        </> 
    );
}

export default Ringbuilderhome;